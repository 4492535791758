console.log('setting zoho unipuka');

// zoho salesiq brand: SOA Staging
window.$zoho=window.$zoho || {};$zoho.salesiq = $zoho.salesiq ||
{widgetcode:"718d252ea5ce21b900417e24c8a0e3bf7a92d131c01dcfdfcc16ea527622fd20", values:{},ready:function(){}};
var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;
s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.write("<div id='zsiqwidget'></div>");

window.$zoho.salesiq.ready = function () {
  window.$zoho.salesiq.chat.logo("https://new.unipuka.app/meta/unipuka.png");
}
